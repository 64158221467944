#okta-sign-in {
    margin: 0px auto;
}

#okta-sign-in .auth-header {
    width: 100%;
    padding: 20px 0 !important;
}

#okta-sign-in .auth-content {
    padding-top: 10px !important;
}

#okta-sign-in input {
    max-width: 100% !important;
}

#okta-sign-in input[type=text]:focus {
    box-shadow: none !important;
}

#okta-sign-in .focused-input {
    border-color:#000000 !important;
    box-shadow: none !important;
}

#okta-sign-in #okta-signin-submit {
    height: 40px !important;
    font-weight: bold;
}
